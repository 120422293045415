@font-face {
  font-family: LendingFont;
  src: url('./font/LexendDeca-Regular.ttf')
}

body {
  margin: 0;
  font-family: LendingFont, -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  background-color: #03000A;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400
}

a:active {
  color: #fff;
}

a:visited {
  color: #fff;
}

img {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
}

img {
  pointer-events: none;
  user-select: none;
}
.link{
  color: #337ab7!important;
  text-decoration: underline;
}